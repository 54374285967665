/* ----------------------- Common desktop header ----------------------- */
.header {
  position: fixed;
  transition: 0.3s ease;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 155px;
  display: flex;
  align-items: center;

  &.scrolled {
    height: 85px;
    background-color: rgba(1,8,18, 0.8);
    backdrop-filter: blur(10px);

    .logo {
      width: 90px;
    }
  }

  .cont {
    @mixin tab-md {
      width: 82%;
    }
  }

  .header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 20;
  }

  .btn {
    font-size: 20px;
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    transform: translateY(-100%);
    transition: 0.3s ease-in;

    padding: ac(146px, 160px) 0 50px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #010812 0%, #193F77 100%) 0 0 no-repeat padding-box;
      opacity: 0.89;
      z-index: -1;
    }

    .cont {
      max-height: 100%;

      display: flex;
      justify-content: space-between;
      max-width: 850px;

      @mixin tab-sm {
        overflow-y: auto;
        display: grid;
        grid-row-gap: 32px;
        grid-column-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
      }

      @mixin mob-lg {
        .navbar__list:first-child, .navbar__list:last-child {
          grid-column: 1 / -1;
        }
      }
    }

    &.active {
      transform: translateY(0);
    }

    @mixin mob-lg {
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);

      &:before {
        opacity: 1;
      }
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .menu-item {
    margin-right: 20px;
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: "\e999";
          font-family: "icomoon";
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @media (--mobile-menu-end-point) {
        padding: 5px 0;
      }

      &:hover {
        @media (--mobile-menu-end-point) {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(16px, 14px);
    @mixin transition-all;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: var(--white);
      @mixin transition-all;
    }

    &:hover {
      color: var(--primary);

      &::before {
        width: 100%;
        left: 0;
        background: var(--primary);
      }
    }
  }
}

.navbar__list {
  &-title {
    font-size: 20px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }
}

.navbar-links__link {
  text-transform: uppercase;
  font-size: 16px;
}

.navbar-links__item {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
  .header:not(.header-mobile) {

  }
}

/* end of Common desktop header Mobile HEADER */


/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  display: flex;

  @mixin transition-all;

  @mixin min-media 1025 {
    &:hover {
      transform: scale(1.1);
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  @mixin transition-all;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
  }
}

/* end of HEADER COMPONENTS */
