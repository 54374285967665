.hero-screen {
  height: 100vh;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 194px;
    left: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(180deg, #01081200 0%, #010812 100%) 0 0
      no-repeat padding-box;
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    /* Overlay */
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--black);
      opacity: 0.4;
      left: 0;
      top: 0;
      pointer-events: none;
    }
  }

  .offer {
    margin-top: ac(-28px, -150px);

    &__title {
      max-width: 612px;
      margin-bottom: ac(44px, 60px);
    }
  }

  &--holding {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(156.69deg, #010812 12.57%, #193f77 84.94%);
      opacity: 0.8;
      z-index: -1;
      top: 0;
      left: 0;
    }

    .contacts {
      max-width: 356px;
      width: 100%;

      @mixin min-media 1920 {
        max-width: 380px;

        &__item {
          max-width: 165px;
        }

        a {
          font-size: 16px;
        }
      }
    }

    .hero-screen__title {
      max-width: 985px;
      font-size: ac(74px, 46px);

      .right {
        margin-left: ac(340px, 20px, 768, 1440);

        @mixin media 551 {
          margin-left: 0;
        }
      }
    }

    .hero-screen__wrap {
      max-width: 95%;
    }

    .hero-screen__logo {
      width: ac(208px, 140px);
      height: 127px;
      margin-top: -68px;
      position: relative;
      flex-shrink: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .circle-bg-item {
        width: 390px;
        height: 377px;
        position: absolute;
      }
    }

    .circles {
      .circle-bg-item {
        &:first-child {
          left: -10%;
          bottom: -28%;
        }
      }
    }

    @mixin media 769 {
      .hero-screen__caption {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }

    @mixin media 901 {
      .hero-screen__wrap {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__form {
    width: 100%;
    max-width: 686px;

    @mixin media 901 {
      max-width: 100%;
    }
  }

  @mixin max-height 769 {
    height: 100%;
    padding-top: ac(260px, 186px);
    padding-bottom: 40px;

    &.hero-screen--holding {
      padding-top: 120px;
      padding-bottom: 80px;
    }

    .offer {
      margin-top: 0;
    }
  }

  @mixin max-height 768 {
    .offer {
      margin-top: 0;
    }
  }
}

/* Brands */
.our-brands {
  padding: ac(115px, 96px) 0 ac(148px, 78px);

  .cont {
    max-width: 1310px;
    width: perc(1310);
  }

  &__title {
    margin-bottom: ac(48px, 40px);
  }

  .circle-bg-item {
    left: ac(-65px, -335px);
    top: 183px;

    @mixin mob-xl {
      top: auto;
      bottom: -25px;
    }
  }
}

.brands-list {
  display: flex;
  justify-content: center;

  @mixin media 1280 {
    flex-wrap: wrap;
  }

  &:container(width < 560px) {
    .brands-list__item {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}

.brands-list__item {
  max-width: ac(207px, 302px, 375, 1366);
  width: 100%;

  @mixin min-media 1440 {
    max-width: 215px;
  }
}

.brands-list__link {
  width: 100%;
  padding: ac(14px, 22px) ac(15px, 26px);
  position: relative;
  border-radius: 20px;
  overflow: hidden;

  &-btn {
    text-transform: uppercase;
    font-size: 16px;
    transition: 0.25s ease;
    line-height: 10px;
    margin-right: 32px;
  }

  &-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--dark-blue);
    position: relative;
    z-index: 5;
    transition: 0.25s ease;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: 20px;
    transition: 0.25s ease;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &-logo {
    width: ac(112px, 159px, 375, 1024);
    transition: filter 0.3s ease;
    transform: translateZ(0);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    .brands-list__link-wrap {
      border-color: var(--white);
    }

    .brands-list__link-bg {
      opacity: 1;
    }

    .brands-list__link-logo {
      filter: brightness(0) invert(1);
    }
  }
}

/* About section */
.about {
  padding-bottom: ac(170px, 100px);
}

.about-card {
  max-width: 1040px;
  width: 100%;
  padding: ac(70px, 40px) ac(106px, 16px) ac(75px, 45px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  margin: 0 auto;

  h2,
  h3 {
    text-align: center;
  }

  .about__title {
    text-transform: none;
  }

  .about__title_lg {
    font-size: ac(60px, 37px);
    color: var(--light-blue);
    margin-bottom: 36px;
    line-height: 100%;
  }

  .about__text-wrap {
    display: flex;

    &:container(width <= 450px) {
      flex-direction: column;

      .about-block {
        max-width: 100%;
        text-align: center;

        &:first-child {
          margin: 0 0 24px;
        }
      }
    }
  }

  .about-block {
    max-width: 398px;

    &:first-child {
      margin-right: 30px;
    }

    p {
      font-size: ac(18px, 16px);
    }

    &_sm {
      p {
        font-size: ac(16px, 14px);
        font-weight: 300;
      }
    }
  }

  .btn-wrap {
    margin-top: 40px;
    justify-content: center;
  }
}

/* Stats */

.stats {
  padding: 76px 0 ac(65px, 48px);

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.15;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.stats-list {
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  flex-wrap: wrap;

  .stats-list__item {
    &:not(:last-child) {
      margin-right: 22px;
    }
  }

  .stats-count-up {
    font-size: ac(90px, 80px);
    font-weight: 200;
    margin-bottom: 20px;
    text-align: center;
    line-height: 100%;
  }

  .stats-list__item-desk {
    font-size: ac(30px, 25px);
    max-width: 260px;
    text-align: center;
    margin: 0 auto;
  }

  &:container(width <= 825px) {
    flex-wrap: wrap;

    .stats-list__item:last-child {
      margin: 42px auto 0;
    }
  }

  &:container(width <= 570px) {
    flex-direction: column;
    align-items: center;

    .stats-list__item:not(:last-child) {
      margin: 0 0 53px;
    }

    .stats-list__item:last-child {
      margin: 0;
    }
  }
}

/* Talent Solutions */
.talent-solutions {
  padding: ac(164px, 100px) 0 ac(170px, 100px);

  &__title {
    margin-bottom: ac(87px, 17px);
  }

  .circle-bg-item {
    bottom: ac(-212px, -290px);
    right: ac(-44px, -375px);
  }
}

.solutions-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @mixin tab-sm {
    flex-direction: column;
    align-items: flex-start;

    .tabs-btns {
      margin-right: 0;
      margin-bottom: 30px;
    }

    .tabs-btns__item-desc {
      max-width: 100%;
    }

    .tabs-images {
      width: 100%;

      .tabs-images__item {
        max-width: 100%;
      }
    }
  }

  @mixin mob-lg {
    .solutions-tabs__wrap {
      width: 100%;
    }

    .tabs-btns__item-desc {
      max-width: 320px;
    }
  }
}

.tabs-btns {
  margin-right: ac(13px, 50px, 768, 1440);
}

.tabs-btns__item {
  opacity: 0.4;
  transition: 0.25s ease;
  cursor: pointer;

  &-content {
    overflow: hidden;
    transition: 0.3s ease;
  }

  &-title {
    font-size: ac(40px, 25px);
    pointer-events: none;
    margin-bottom: 6px;
    transition: 0.3s ease;
    position: relative;

    @mixin tab-sm {
      padding-right: 15px;
    }
  }

  &-desc {
    font-weight: 200;
    max-width: 535px;
    margin-bottom: 22px;
    transition: 0.3s ease;
  }

  .plus {
    position: absolute;
    font-size: 25px;
    font-weight: 300;
    top: 2px;
    right: 0;
    display: none;
    transition: opacity 0.3s ease;

    @mixin tab-sm {
      display: block;
    }
  }

  &.active {
    cursor: auto;
    opacity: 1;
    margin-bottom: ac(23px, 27px);

    .plus {
      opacity: 0;
    }

    .tabs-btns__item-title {
      margin-bottom: ac(5px, 10px);
    }

    .tabs-btns__item-content {
      overflow: visible;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.tabs-images__item {
  max-height: ac(630px, 178px, 375, 768);
  height: 100%;
  max-width: ac(630px, 302px, 375, 768);
  border-radius: 30px;
  overflow: hidden;

  display: none;

  img {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.active {
    display: block;
    animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Latest media */
.blog-section {
  padding: ac(72px, 50px) 0 72px;
  margin-bottom: ac(120px, 100px);

  &__title {
    width: fit-content;
    margin: 0 auto -52px;

    @mixin media 990 {
      margin-bottom: 37px;
    }

    @mixin media 415 {
      margin: * 0;
    }
  }

  .blog-list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    .blog-list__item:nth-child(2) {
      margin-top: ac(66px, 61px, 1366, 1440);

      .article-card__preview {
        height: 155px;
      }
    }

    &:container(width <= 550px) {
      grid-template-columns: 1fr;

      .blog-list__item {
        &:not(:last-child) {
          margin-bottom: 17px;
        }
      }

      .article-card {
        max-width: 100%;
      }
    }

    @mixin media 990 {
      grid-template-columns: repeat(2, 1fr);

      .blog-list__item {
        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(2) {
          align-self: auto;

          .article-card__preview {
            height: ac(220px, 166px);
          }
        }
      }
    }
  }
}

.article-card {
  max-width: 398px;
  border-radius: 10px;
  height: 100%;

  display: flex;
  flex-direction: column;

  &__preview {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: ac(220px, 166px);
    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      transition: 0.25s ease;
    }
  }

  &__title {
    margin-bottom: 8px;
    line-height: 130%;

    @mixin max-line-length 3;
  }

  &__desc {
    color: var(--grey);
    margin-bottom: 18px;
    font-weight: 300;
    font-size: ac(14px, 12px);
    transition: 0.25s ease;

    @mixin max-line-length 3;
  }

  .btn {
    margin-top: auto;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }

    .article-card__desc {
      color: var(--white);
    }

    .btn:before {
      transform: translate(-50%, -50%) scale(1.15);
    }
  }
}
