.footer {
  background: var(--dark-blue);
  padding: ac(98px, 53px) 0 ac(100px, 30px);

  .navbar {
    display: flex;
    justify-content: space-between;
    max-width: 1115px;
    padding-bottom: ac(50px, 25px);
  }

  .navbar__list {
    &-title {
      font-size: ac(30px, 25px);
      font-weight: 200;
      text-transform: none;
    }
  }

	.contacts {
		padding-top: ac(50px, 30px);
		border-top: 1px solid var(--white-rgba);
		width: 100%;
		max-width: 607px;

		&__item:first-of-type {
			margin: 0 11% 0 17%;
		}
	}

  &__info {
    display: flex;
    padding-bottom: ac(45px, 30px);
    border-bottom: 1px solid var(--white-rgba);

    &:container(width <= 700px) {
      flex-direction: column;

      .footer__text {
        padding-bottom: 28px;
        max-width: 100%;

        p {
          max-width: 100%;
        }
      }

      .footer__contacts {
        justify-content: flex-start;
        max-width: 100%;

        .footer__contacts-item {
          flex: 1 1 auto;

          &:first-of-type {
            margin: 0;
          }

          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
    }
  }

  &__text {
    max-width: 615px;
    width: 100%;
    padding-top: ac(50px, 30px);
    border-top: 1px solid var(--white-rgba);
    margin-right: 30px;

    p {
      font-size: ac(18px, 16px);
      max-width: 428px;
    }
  }

  &__credits {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    p, a {
      color: var(--grey);
      font-size: 14px;
      font-weight: 300;
    }

    &:container(width <= 400px) {
      flex-direction: column;

      .footer__credits-copyright {
        margin-bottom: 14px;
      }
    }
  }

  &__policy-link {
    transition: 0.25s ease-in-out;

    &:hover {
      color: var(--white);
    }

    &:not(:last-child) {
      margin-right: 23px;
    }
  }

  @mixin tab {
    .navbar {
      display: grid;
      grid-row-gap: 38px;
      grid-column-gap: 15px;
      grid-template-columns: repeat(3, 1fr);
    }

    .footer__contacts {
      justify-content: center;
    }
  }

  @mixin mob-lg {
    .navbar {
      grid-template-columns: repeat(2, 1fr);

      .logo {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }

  @mixin media 415 {
    .logo {
      justify-self: end;
    }
  }
}

.contacts {
  display: flex;
  flex: 0 0 auto;
	gap: 20px;

  &__item {
    max-width: 148px;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    a {
      font-size: 14px;
      font-weight: 300;
    }

    @mixin media 415 {
      max-width: 128px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }

  &__address {
    color: var(--grey);
    margin-bottom: 6px;

    &:hover {
      color: var(--light-blue);
    }
  }

  &__link {
    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &:hover {
      color: var(--light-blue);
    }
  }

  @mixin tab {
    flex: 1 1 auto;
  }
}
