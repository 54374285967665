/* ----- Buttons ----- */

.btn {
    display: block;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
    transition: 0.25s ease;
    width: fit-content;
    z-index: 20;

    &:before {
        content: '';
        position: absolute;
        size: 30px;
        border-radius: 50%;
        border: 0.5px solid var(--white);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.25s ease;
    }

    &:hover {
        &:before {
            transform: translate(-50%, -50%) scale(1.15);
        }
    }
}

.btn-wrap {
    display: flex;
    align-items: center;

    .btn:not(:last-child) {
        margin-right: 30px;
    }
}