html {
  font-size: 16px;

  @mixin media 1450 {
    font-size: 14px;
  }

  @mixin media 1025 {
    font-size: 12px;
  }

  @mixin media 551 {
    font-size: 10px;
  }
}

.fp-watermark {
  display: none;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  font-family: var(--font-main);
  background: var(--black-second);
  color: var(--white);

  max-width: 100%;
  overflow-x: hidden;

  &.disabled {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: var(--black-second);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey);
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  overflow: hidden;
}

.cont {
  margin: 0 auto;
  max-width: 1260px;
  width: perc(1260);

  @mixin tab-md {
    width: 89%;

    &.sm {
      width: 82%;
    }
  }
}

section {
  position: relative;
}

.logo {
  height: 65px;
  width: 107px;
  flex-shrink: 0;
  transition: 0.25s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.line {
  position: absolute;
  width: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.3s linear;

  &.aos-animate {
    width: 43%;
  }
}

.main-link {
  text-transform: uppercase;
  display: inline;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 5px;
    size: 9px;
    border-radius: 50%;
    border: 1px solid var(--white);
    transition: 0.25s ease;
  }

  &:hover {
    &:after {
      background: var(--white);
    }
  }
}

.social-link {
  transition: 0.25s ease;

  i {
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
    transition: 0.25s ease;
  }

  &:hover {
    color: var(--light-blue);
  }
}

.circle-bg-item {
  position: absolute;
  width: 713px;
  height: 684px;
  animation: rotate 20s linear infinite both;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
