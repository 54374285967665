@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?wn83oa');
  src:  url('../fonts/icomoon.eot?wn83oa#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.woff2?wn83oa') format('woff2'),
  url('../fonts/icomoon.ttf?wn83oa') format('truetype'),
  url('../fonts/icomoon.woff?wn83oa') format('woff'),
  url('../fonts/icomoon.svg?wn83oa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-inst:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-twitter:before {
  content: "\e903";
}


h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: var(--font-main);
  color: var(--white);
}

h1 {
  font-size: ac(70px, 47px);
  line-height: 133%;

  margin-bottom: ac(18px, 10px);

  @mixin mob-sm {
    font-size: 41px;
  }
}

h2 {
  font-size: ac(50px, 35px);
  font-weight: 200;
  text-transform: capitalize;
  line-height: 130%;
}

h3 {
  font-size: ac(30px, 25px);
  font-weight: 200;
  line-height: 140%;
}

p {
  font-size: ac(16px, 14px);
  font-weight: 400;
}

a {
  font-size: ac(16px, 14px);
  font-weight: 200;
  color: var(--white);
  transition: 0.25s ease;
}

span, label, a {
  display: inline-block;
}

.content-element {
  p:not(:last-child) {
    margin-bottom: 25px;
  }

  ul, ol {
    margin: 16px 0;
    padding-left: 15px;

    li {
      font-size: ac(16px, 14px);
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

}

