input,
textarea {
  background: transparent;
  font-size: ac(18px, 16px);
  padding-bottom: 12px;
  color: var(--white);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
  width: 100%;

  &::placeholder {
    opacity: 0.8;
  }

  &:focus {
    border-color: var(--white);
  }

  @mixin transparent-bg-input var(--white);
}

textarea {
  resize: none;
  height: 52px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey);
  }
}

.input-item {
  width: 100%;
}
