@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&family=Train+One&display=swap');

:root {
  --black: #000000;
  --black-second: #010812;
  --white: #ffffff;
  --dark-blue: #193F77;
  --light-blue: #57C1DA;
  --blue: #38A4D4;
  --green: #018E82;
  --grey: #ADADAD;

  --white-rgba: rgba(255, 255, 255, 0.2);

  --font-main: 'Source Sans Pro', sans-serif;
  --font-title: 'Train One', cursive;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
